.contact-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 56px;
  color: #fff;
  .contact-title {
    font-family: Poppins-SemiBold;
    font-size: 48px;
    line-height: 72px;
    letter-spacing: 0.07rem;
    margin-bottom: 24px;
  }
  .contact-block {
    background: #0b0b0b;
    border-radius: 16px;
    height: 460px;
    display: flex;
    align-items: center;
    width: 100%;
    .contact-sub-block {
      display: flex;
      width: 50%;
      flex-direction: column;
      align-items: center;
      padding: 108px 0 96px;
      box-sizing: border-box;
      height: 460px;
      .block-icon {
        width: 120px;
        margin-bottom: 40px;
      }
      .block-title {
        font-family: Poppins-Bold;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 12px;
      }
      .block-desc {
        display: flex;
        flex-direction: column;
        align-items: center;
        .block-desc-row {
          font-family: Poppins-Regular;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}
