.scaled-container {
  position: relative;
  .origin-size-image {
    width: 100%;
    display: block;
  }
  .origin-size-wrapper {
    position: absolute;
    transform-origin: center;
    top: 50%;
    left: 50%;
  }
}
