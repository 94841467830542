
.download-wrapper {
  padding: 16px 56px 72px;
  background: #000;
  .top-info-block {
    background: #0b0b0b;
    width: 100%;
    border-radius: 16px;
    padding: 40px;
    box-sizing: border-box;
    color: #fff;
    .top-title {
      font-family: Poppins-Bold;
      font-size: 32px;
      line-height: 48px;
    }
    .top-desc {
      font-family: Poppins-Regular;
      font-size: 16px;
      line-height: 24px;
      margin-top: 8px;
    }
  }
  .detail-block {
    margin-top: 16px;
    width: 100%;
    display: flex;
    .menu-wrapper {
      background: #0b0b0b;
      border-radius: 16px;
      width: 280px;
      padding: 0 16px;
      .menu-item {
        height: 48px;
        border-bottom: 1px solid #3b3b3b;
        font-family: Poppins-SemiBold;
        font-size: 16px;
        line-height: 48px;
        color: #fff;
      }
      .sub-menu-item {
        height: 48px;
        border-bottom: 1px solid #3b3b3b;
        font-family: Poppins-Regular;
        font-size: 16px;
        line-height: 24px;
        color: #dedede;
        padding: 12px;
        box-sizing: border-box;
        cursor: pointer;
        &.active {
          background: #2d2d2d;
        }
        &.disabled {
          cursor: not-allowed;
        }
      }
    }
    .item-detail-wrapper {
      margin-left: 16px;
      background: #0b0b0b;
      border-radius: 16px;
      flex-grow: 1;
      padding: 24px 24px 48px;
      align-self: flex-start;
      display: flex;
      flex-direction: column;
      .item-title {
        color: #fff;
        font-family: Poppins-Regular;
        font-size: 32px;
        line-height: 48px;
      }
      .item-img {
        width: 524px;
        margin: 24px 0;
      }
      .item-content-block {
        color: #fff;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
        &.one-row {
          display: flex;
          align-items: flex-start;
        }
        .item-content-title {
          font-family: Poppins-SemiBold;
        }
        .item-content-value {
          font-family: Poppins-Regular;
        }
      }
      .download-button {
        margin-top: 16px;
        width: 220px;
        height: 48px;
        border-radius: 4px;
        font-size: 16px;
        font-family: Poppins-Regular;
      }
    }
  }
}
