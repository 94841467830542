.home-wrapper {
  .block-1 {
    .title {
      color: #fff;
      font-family: Poppins-Bold;
      font-size: 64px;
      line-height: 96px;
      position: absolute;
      top: 120px;
      left: 100px;
      letter-spacing: 0.08rem;
    }
    .description {
      width: 580px;
      position: absolute;
      left: 100px;
      top: 336px;
      font-size: 16px;
      line-height: 24px;
      color: #fff;
      font-family: Poppins-Regular;
      letter-spacing: 0.02rem;
    }
    .download-button {
      border-radius: 4px;
      padding: 21px 38px;
      font-size: 16px;
      line-height: 21px;
      font-family: Poppins-Medium;
      height: 48px;
      position: absolute;
      top: 511px;
      left: 100px;
    }
  }
  .block-2 {
    background: #000;
    color: #fff;
    .title {
      font-family: Poppins-Light;
      font-size: 100px;
      line-height: 100px;
      position: absolute;
      top: 120px;
      left: 160px;
    }
    .description {
      font-family: Poppins-Regular;
      width: 630px;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.026rem;
      position: absolute;
      top: 126px;
      left: 720px;
    }
    .feature-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 240px;
      position: absolute;
      .block-img {
        width: 160px;
      }
      .block-title {
        margin-top: 24px;
        font-family: Poppins-Regular;
        font-size: 20px;
        line-height: 30px;
      }
      &.feature-block-left {
        left: 160px;
        top: 428px;
      }
      &.feature-block-center {
        left: 598px;
        top: 428px;
      }
      &.feature-block-right {
        left: 1041px;
        top: 428px;
      }
    }
  }
  .block-3 {
    background: #000;
    color: #fff;
    .title {
      font-family: Poppins-SemiBold;
      font-size: 48px;
      line-height: 72px;
      position: absolute;
      top: 80px;
      left: 50%;
      transform: translateX(-50%);
    }
    .block-img {
      position: absolute;
      height: 214px;
      background: #0b0b0b;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      &:hover {
        background: #191919;
      }
      &.img-1 {
        width: 700px;
        top: 232px;
        left: 189px;
        .img {
          height: 85px;
        }
      }
      &.img-2 {
        width: 338px;
        top: 232px;
        left: 913px;
        .img {
          height: 72px;
        }
      }
      &.img-3 {
        width: 519px;
        top: 470px;
        left: 189px;
        .img {
          height: 76px;
        }
      }
      &.img-4 {
        width: 519px;
        top: 470px;
        left: 732px;
        .img {
          height: 76px;
        }
      }
    }
  }
  .block-4 {
    background: #000;
    color: #fff;
    .title {
      font-family: Poppins-SemiBold;
      font-size: 48px;
      line-height: 72px;
      position: absolute;
      width: 722px;
      top: 80px;
      left: 50%;
      text-align: center;
      transform: translateX(-50%);
    }
    .description {
      font-family: Poppins-Regular;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.02rem;
      width: 802px;
      position: absolute;
      top: 176px;
      left: 319px;
      text-align: center;
    }
    .feature-wrapper {
      position: absolute;
      top: 264px;
      left: 120px;
      right: 120px;
      height: 440px;
      display: flex;
      justify-content: space-between;
      .feature-block {
        flex-shrink: 0;
        // background-color: #fff;
        background-blend-mode: multiply;
        background-position: center;
        background-size: 596px 440px;
        background-repeat: no-repeat;
        background-color: rgba(#000, 0.2);
        border-radius: 16px;
        width: 282px;
        transition: all 0.3s;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 32px;
        box-sizing: border-box;
        &.hovered-block {
          background-color: rgba(#000, 0.4);
          width: 596px;
        }
        .feature-icon {
          width: 80px;
          margin-bottom: 32px;
        }
        .feature-title {
          font-family: Poppins-SemiBold;
          font-size: 24px;
          line-height: 36px;
          margin-bottom: 12px;
        }
        .feature-content {
          font-family: Poppins-Regular;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .block-5 {
    background: #121317;
    color: #fff;
    .title {
      font-family: Poppins-SemiBold;
      font-size: 48px;
      line-height: 72px;
      position: absolute;
      top: 80px;
      left: 50%;
      transform: translateX(-50%);
    }
    .subtitle {
      font-family: Poppins-SemiBold;
      font-size: 20px;
      line-height: 30px;
      position: absolute;
      &.subtitle-1 {
        top: 192px;
        left: 50%;
        transform: translateX(-50%);
      }
      &.subtitle-2 {
        top: 374px;
        left: 50%;
        transform: translateX(-50%);

      }
    }
    .big-icon {
      height: 96px;
      display: block;
      position: absolute;
      opacity: 0.6;
      &:hover {
        opacity: 1;
      }
      &.big-icon-1 {
        left: 120px;
        top: 238px;
      }
      &.big-icon-2 {
        left: 571px;
        top: 238px;
      }
      &.big-icon-3 {
        left: 1020px;
        top: 238px;
      }
    }
    .small-icon {
      height: 96px;
      display: block;
      position: absolute;
      opacity: 0.6;
      &:hover {
        opacity: 1;
      }
      &.small-icon-1 {
        left: 120px;
        top: 416px;
      }
      &.small-icon-2 {
        left: 360px;
        top: 416px;
      }
      &.small-icon-3 {
        left: 600px;
        top: 416px;
      }
      &.small-icon-4 {
        left: 840px;
        top: 416px;
      }
      &.small-icon-5 {
        left: 1080px;
        top: 416px;
      }
    }
  }
}
