.support-wrapper {
  padding: 16px 56px 72px;
  background: #fff;
  .top-info-block {
    background: #fff;
    width: 100%;
    border-radius: 16px;
    padding: 40px;
    box-sizing: border-box;
    color: #000;
    .top-title {
      font-family: Poppins-Bold;
      font-size: 32px;
      line-height: 48px;
    }
    .top-desc {
      font-family: Poppins-Regular;
      font-size: 16px;
      line-height: 24px;
      margin-top: 8px;
    }
  }
  .docs-wrapper {
    display: flex;
    margin-top: 16px;
    .support-menu {
      width: 350px;
      flex-shrink: 0;
      border-radius: 16px;
      background: #fff;
      max-height: calc(100vh - 180px);
      overflow: auto;
      .ant-menu-sub {
        &.ant-menu-inline {
          background: #fff;
        }
      }
    }
    .markdown-wrapper {
      background: #fff;
      margin-left: 16px;
      border-radius: 16px;
      padding: 24px;
      flex: 1;
      // color: #fff;
      overflow-x: auto;
      .code-wrapper {
        max-width: 100%;
        overflow: auto;
        border: 2px solid #ededed;
        padding: 8px;
      }
    }
  }
}
