.eulix-header {
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 38px 0 40px;
  background: #111;
  .demo-logo {
    display: block;
    height: 32px;
    cursor: pointer;
  }
  .eulix-header-menu {
    margin-left: 40px;
    display: flex;
    align-items: center;
    font-family: Poppins-Regular;
    font-weight: 400;
    .eulix-menu-item {
      color: #fff;
      padding: 24px 16px;
      font-size: 20px;
      line-height: 30px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
  .eulix-header-right {
    margin-left: auto;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    cursor: pointer;
    .locale {
      width: 40px;
      padding: 24px 8px;
    }
    .locale-text {
      font-family: Poppins-Regular;
      font-size: 20px;
      line-height: 30px;
      width: 25px;
    }
    .download-button {
      border-radius: 4px;
      padding: 11px 32px;
      font-size: 14px;
      line-height: 21px;
      font-family: Poppins-Medium;
      height: 45px;
    }
  }
}
.content {
  background: #fff;
}
.eulix-footer {
  background: #121317;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .footer-nav-row {
    display: flex;
    align-items: center;
    .footer-nav-item {
      color: #818181;
      width: 100px;
      text-align: center;
      font-size: 16px;
      line-height: 24px;
      font-family: Poppins-Regular;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .copyright {
    margin-top: 38px;
    font-size: 14px;
    line-height: 21px;
    font-family: Poppins-Regular;
    color: #777;
  }
}
.ant-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1;
    background: #000;
  }
}
.eulix-dropdown-menu-item {
  color: #f5f5f5;
  font-size: 20px;
  line-height: 24px;
  font-family: Poppins-Regular;
}
.eulix-dropdown {
  .ant-dropdown-menu {
    background: #26282c;
    border-radius: 16px;
    padding: 8px;
    .ant-dropdown-menu-item {
      padding: 12px !important;
      border-radius: 8px !important;
    }
    .ant-dropdown-menu-item-active {
      background: #393c43 !important;
    }
  }
}
