.products-wrapper {
  .block-1 {
    .title {
      color: #fff;
      font-family: Poppins-SemiBold;
      font-size: 64px;
      line-height: 96px;
      position: absolute;
      top: 157px;
      left: 99px;
      letter-spacing: 0.06rem;
    }
    .description {
      width: 580px;
      position: absolute;
      left: 99px;
      top: 277px;
      font-size: 16px;
      line-height: 24px;
      color: #fff;
      font-family: Poppins-Regular;
      letter-spacing: 0.015rem;
    }
    .download-button {
      border-radius: 4px;
      padding: 21px 36px;
      font-size: 16px;
      line-height: 21px;
      font-family: Poppins-Medium;
      height: 48px;
      position: absolute;
      top: 487px;
      left: 100px;
    }
  }
  .block-2 {
    color: #fff;
    background: #000;
    .left-block {
      position: absolute;
      width: 720px;
      height: 810px;
      left: 0;
      top: 0;
    }
    .text-block {
      display: flex;
      flex-direction: column;
      padding: 64px 64px 120px;
      justify-content: space-between;
      position: relative;
      .title {
        font-family: Poppins-SemiBold;
        font-size: 64px;
        line-height: 96px;
        letter-spacing: 0.07rem;
      }
      .content-wrapper {
        .content-para-wrapper {
          transition: all 0.3s ease-in-out;
          position: absolute;
          bottom: 120px;
          &.display-none-content {
            opacity: 0;
          }
        }
        .content-para {
          width: 584px;
          font-family: Poppins-Regular;
          font-size: 20px;
          line-height: 30px;
          letter-spacing: 0.024rem;
          margin-bottom: 24px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .index-bar-container {
        position: absolute;
        top: 719px;
        left: 59px;
        display: flex;
        align-items: center;
        .index-bar-wrapper {
          padding: 4px;
          .index-bar {
            background: rgba(#fff, 0.4);
            width: 56px;
            height: 4px;
            transition: all 0.3s ease-in-out;
            &.activity-bar {
              background: #fff;
            }
          }
        }
      }
    }
    .right-block {
      position: absolute;
      width: 720px;
      height: 810px;
      right: 0;
      top: 0;
    }
  }
}
